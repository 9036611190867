// __     __         _       _     _
// \ \   / /_ _ _ __(_) __ _| |__ | | ___  ___
//  \ \ / / _` | '__| |/ _` | '_ \| |/ _ \/ __|
//   \ V / (_| | |  | | (_| | |_) | |  __/\__ \
//    \_/ \__,_|_|  |_|\__,_|_.__/|_|\___||___/
//

//
// Colors
//
$color-presets-rega: #a00;
$color-presets-military: #0a0;
$color-presets-heliport: #369;
$color-presets-hospital: #fa0;
$color-presets-route: #333;

//
// Viewport
//
$viewport-phone: 400px;
$viewport-phablet: 600px;
$viewport-tablet: 700px;
$viewport-desktop: 1000px;
$viewport-tv: 3000px;

//
// Breakpoints
//

//
// Typography
//
$font-family-regular: ('glass_gaugeregular', sans-serif);
$font-family-bold: $font-family-regular;
$font-family-italic: $font-family-regular;

//
// Headings
//
$h1-font-size: 30px;
$h1-line-height: 1;
$h1-padding-bottom: 0.25em;

$h2-font-size: 20px;
$h2-line-height: 1.2;
$h2-padding-bottom: 0.5em;

$h3-font-size: 16px;
$h3-line-height: 1.5;
$h3-padding-bottom: 0;

$h4-font-size: 16px;
$h4-line-height: 1.5;
$h4-padding-bottom: 0;

$h5-font-size: 16px;
$h5-line-height: 1.5;
$h5-padding-bottom: 0;

$h6-font-size: 16px;
$h6-line-height: 1.5;
$h6-padding-bottom: 0;

//
// Sprite
//
// https://css-tricks.com/svg-fallbacks/
//
$sprite-url: './../../img/sprite.svg';
$sprite-url-fallback: './../../img/sprite.png';

$sprite-data: (
  'play-default': (
    'position': (
      -0px -0px,
    ),
    'width': 14px,
    'height': 19px,
  ),
  'pause-default': (
    'position': (
      -0px -50px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'radiobutton-default': (
    'position': (
      -0px -100px,
    ),
    'width': 12px,
    'height': 12px,
  ),
  'radiobutton-focus': (
    'position': (
      -50px -100px,
    ),
    'width': 12px,
    'height': 12px,
  ),
  'radiobutton-checked': (
    'position': (
      -100px -100px,
    ),
    'width': 12px,
    'height': 12px,
  ),
  'checkbox-default': (
    'position': (
      -0px -150px,
    ),
    'width': 13px,
    'height': 12px,
  ),
  'checkbox-focus': (
    'position': (
      -50px -150px,
    ),
    'width': 13px,
    'height': 12px,
  ),
  'checkbox-checked': (
    'position': (
      -100px -150px,
    ),
    'width': 13px,
    'height': 12px,
  ),
  'knob-default': (
    'position': (
      -0px -200px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'fullscreen-enable': (
    'position': (
      -0px -250px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'fullscreen-disable': (
    'position': (
      -50px -250px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'zoom-in-default': (
    'position': (
      -0px -300px,
    ),
    'width': 21px,
    'height': 21px,
  ),
  'zoom-in-disabled': (
    'position': (
      -150px -300px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'zoom-out-default': (
    'position': (
      -0px -350px,
    ),
    'width': 21px,
    'height': 5px,
  ),
  'zoom-out-disabled': (
    'position': (
      -150px -350px,
    ),
    'width': 20px,
    'height': 20px,
  ),
  'icon-help': (
    'position': (
      -0px -400px,
    ),
    'width': 15px,
    'height': 15px,
  ),
);
