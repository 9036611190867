.widget-control-presetslegend {
  margin: 0 !important;
  padding: 0.2em 0.5em;
  width: 12em;

  background-color: #1c251c;
  color: #0f0;

  border: 1px solid #0f0;
  border-bottom: 0;
  border-left: 0;

  .presetslegend-categories {
    @extend %list-style-none;
  }

  .presetslegend-category {
    position: relative;
    padding-left: 20px;
  }

  .presetslegend-color {
    position: absolute;
    top: 0.3em;
    left: 0;

    display: block;
    width: 10px;
    height: 10px;

    border-radius: 50%;

    &-rega {
      background-color: $color-presets-rega;
    }
    &-military {
      background-color: $color-presets-military;
    }
    &-heliport {
      background-color: $color-presets-heliport;
    }
    &-hospital {
      background-color: $color-presets-hospital;
    }
    &-route {
      background-color: $color-presets-route;
    }
  }
}
