// Vendor
@import '../../node_modules/leaflet/dist/leaflet';

// Utilities
@import 'utilities/normalize';
@import 'utilities/variables';
@import 'utilities/helpers';

// Atoms
@import 'atoms/button';

// Molecules
@import 'molecules/form-item';
@import 'molecules/form-textinput';
@import 'molecules/form-select';

// Organisms
@import 'organisms/regions';
@import 'organisms/crosssection';
@import 'organisms/scrolllist';
@import 'organisms/forecasts';
@import 'organisms/waypoints';
@import 'organisms/modal';

// Templates
//@import "templates/";

// Pages
//@import "pages/";

@import 'widget/index';

body {
  @extend %font-weight-normal;

  margin: 0;
  padding: 0;

  background-color: #1c251c;
  color: #0f0;
  font-size: 15px;
}

.crosssection,
.forecasts,
.map,
.waypoints {
  position: relative;
  height: 100%;
  border: 1px solid #0f0;
}

.fatalerror {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  text-align: center;
  white-space: nowrap;

  font-size: 1.8em;

  &:before {
    display: inline-block;
    margin-right: -0.25em;
    height: 100%;
    vertical-align: middle;
    content: '';
  }

  & > div {
    display: inline-block;
    vertical-align: middle;
    width: 90%;
  }

  p {
    margin: 0;
    padding-bottom: 1em;
  }
}

@media (max-width: 767px), (max-height: 599px) {
  .regions {
    display: none;
  }

  .fatalerror {
    display: block;
  }
}
