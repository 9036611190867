.regions {
  @extend %list-style-none;

  position: absolute;
  top: 16px;
  right: 16px;
  bottom: 16px;
  left: 16px;

  margin: 0;
  padding: 0;
}

.region--crosssection {
  position: absolute;
  top: 0;
  left: 0;
  right: 272px;
  height: 256px;
}

.region--forecasts {
  position: absolute;
  top: 0;
  right: 0;
  width: 256px;
  height: 256px;
}

.region--map {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 272px;
  top: 272px;
}

.region--waypoints {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 272px;
  width: 256px;
}
