$layerslider-lineheight: 1.5em;

.widget-control-layerslider {
  top: 0;
  right: 0;

  margin: 0 !important;

  width: 44px;

  border-left: 1px solid #0f0;
  background-color: #1b251b;

  .noUi-base {
    position: absolute;
    top: 35px;
    bottom: 35px;

    width: 100%;

    &:before {
      position: absolute;
      top: 0;
      left: 50%;

      width: 4px;
      height: 100%;
      margin-left: -2px;

      background-color: #3db03d;
      content: '';
    }
  }

  .noUi-origin {
    position: absolute;
    width: 100%;
  }

  .noUi-handle {
    position: absolute;
    top: 0;
    left: 50%;

    margin-top: -10px;
    margin-left: -10px;

    width: 20px;
    height: 20px;

    background-color: #0f0;
    border-radius: 50%;
  }

  .noUi-tooltip {
    position: absolute;
    top: #{$layerslider-lineheight * -1};
    right: 45px;

    padding: 0 0.6em;
    background-color: #1b251b;
    text-align: center;
    white-space: nowrap;
    color: #0f0;

    height: #{$layerslider-lineheight * 3};
    line-height: $layerslider-lineheight;

    &:before {
      position: absolute;
      top: 50%;
      right: -15px;

      margin-top: -8px;

      border: 8px solid transparent;
      border-left-color: #1b251b;

      content: '';
    }
  }
}
