.widget-control-timeslider {
  @extend %clearfix;

  position: absolute;
  left: 44px;
  right: 0;
  bottom: 0;

  height: 44px;

  float: none !important;
  margin: 0 !important;
  padding: 0 50px 0 15px;

  border-top: 1px solid #0f0;
  background-color: #1c251c;
}

.widget-control-timeslider .timeslider-toggle {
  float: left;
  width: 44px;
  margin-right: 10px;
}

.widget-control-timeslider .timeslider-track {
  @extend %clearfix;

  float: left;
  position: relative;
  height: 100%;
  width: 100%;

  cursor: pointer;
}

.widget-control-timeslider .timeslider-past,
.widget-control-timeslider .timeslider-future {
  float: left;
  height: 24px;

  border-bottom: 4px solid;
}

.widget-control-timeslider .timeslider-past {
  border-color: #3db03d;
}

.widget-control-timeslider .timeslider-future {
  border-color: darken(#3db03d, 20%);
}

.widget-control-timeslider .timeslider-current {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  &:after {
    display: block;

    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -10px;
    margin-right: -10px;

    width: 20px;
    height: 20px;

    background-color: #0f0;
    border-radius: 50%;

    content: '';
  }
}

.widget-control-timeslider .timeslider-tooltip {
  position: absolute;
  top: -40px;
  width: 8.5em;
  margin-left: -4.25em;
  padding: 0.2em 0.6em;

  background-color: #1b251b;
  text-align: center;
  color: #0f0;

  &:before {
    position: absolute;
    top: 100%;
    left: 50%;

    margin-left: -8px;

    border: 8px solid transparent;
    border-top-color: #1b251b;

    content: '';
  }
}
