.widget-flightpath-waypoint {
  display: block;
  width: 20px !important;
  height: 20px !important;
  margin-top: -10px !important;
  margin-left: -10px !important;

  border-radius: 50%;
  border: 1px solid #0f0;

  background-color: #1c251c;
  color: #0f0;

  text-align: center;
}
