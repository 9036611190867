.widget {
  .leaflet-control-zoom {
    margin: 0;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    position: relative;

    width: 44px !important;
    height: 44px !important;

    border-radius: 0 !important;

    border: 0;
    border-right: 1px solid #0f0;
    border-bottom: 1px solid #0f0 !important;
    background-color: #1c251c;
    -webkit-tap-highlight-color: transparent;

    color: transparent !important;
    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;

    &.leaflet-disabled {
      background-color: #1c251c !important;
    }

    &:before {
      @extend %sprite;

      position: absolute;
      top: 50%;
      left: 50%;

      content: '';
    }

    &:hover,
    &:focus {
      background-color: #1c251c;
    }
  }

  .leaflet-control-zoom-in:before {
    width: sprite-width('zoom-in-default');
    height: sprite-height('zoom-in-default');
    margin-left: sprite-width('zoom-in-default') / 2 * -1;
    margin-top: sprite-height('zoom-in-default') / 2 * -1;
    background-position: sprite-position('zoom-in-default');
  }

  .leaflet-control-zoom-in.leaflet-disabled:before {
    width: sprite-width('zoom-in-disabled');
    height: sprite-height('zoom-in-disabled');
    margin-left: sprite-width('zoom-in-disabled') / 2 * -1;
    margin-top: sprite-height('zoom-in-disabled') / 2 * -1;
    background-position: sprite-position('zoom-in-disabled');
  }

  .leaflet-control-zoom-out:before {
    width: sprite-width('zoom-out-default');
    height: sprite-height('zoom-out-default');
    margin-left: sprite-width('zoom-out-default') / 2 * -1;
    margin-top: sprite-height('zoom-out-default') / 2 * -1;
    background-position: sprite-position('zoom-out-default');
  }

  .leaflet-control-zoom-out.leaflet-disabled:before {
    width: sprite-width('zoom-out-disabled');
    height: sprite-height('zoom-out-disabled');
    margin-left: sprite-width('zoom-out-disabled') / 2 * -1;
    margin-top: sprite-height('zoom-out-disabled') / 2 * -1;
    background-position: sprite-position('zoom-out-disabled');
  }
}
