/*  _   _      _
// | | | | ___| |_ __   ___ _ __ ___
// | |_| |/ _ \ | '_ \ / _ \ '__/ __|
// |  _  |  __/ | |_) |  __/ |  \__ \
// |_| |_|\___|_| .__/ \___|_|  |___/
//              |_|
*/

//
// Clear-Fix
//
// http://css-tricks.com/snippets/css/clear-fix/
//

%clearfix:after {
  display: table;
  clear: both;
  content: '';
}

//
// Hiding
//
%hide {
  &-visually {
    position: absolute;
    left: -999999px;

    padding: 0;
    width: 0;
    height: 0;
    line-height: 0;
  }

  &-content {
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: transparent;
    direction: ltr;
  }

  &-completely {
    display: none !important;
    visibility: hidden;
  }
}

%list-style-none {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;
}

//
// Box-Sizing
//
// https://css-tricks.com/box-sizing/
// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
//

html {
  -webkit-box-sizing: border-box; // Safari 3.0 - 5.0, Chrome 1 - 9, Android 2.1 - 3.x
  -moz-box-sizing: border-box; // Firefox 1 - 28
  box-sizing: border-box; // Safari 5.1+, Chrome 10+, Firefox 29+, Opera 7+, IE 8+, Android 4.0+, iOS any
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

//
// Sprite
//

// Get background-position.
@function sprite-position($key) {
  @return map-get(map-get($sprite-data, $key), 'position');
}

// Get width.
@function sprite-width($key) {
  @return map-get(map-get($sprite-data, $key), 'width');
}

// Get height.
@function sprite-height($key) {
  @return map-get(map-get($sprite-data, $key), 'height');
}

// Use on &:before pseudo-elements.
%sprite {
  background-image: url($sprite-url-fallback);
  background-image: url($sprite-url), none;
  background-repeat: no-repeat;
  overflow: hidden;
  content: '';
}

//
// Fonts
//

@font-face {
  font-family: 'glass_gaugeregular';
  src: url('./../../fonts/glass_gauge-webfont.woff2') format('woff2'),
    url('./../../fonts/glass_gauge-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

%font-weight-normal {
  font-family: $font-family-regular;
  font-weight: normal;
}

%font-weight-bold {
  font-family: $font-family-bold;
  font-weight: bold;
}

%font-style-italic {
  font-family: $font-family-italic;
  font-style: italic;
}
