.presets {
  width: 10px;
  height: 10px;

  border-radius: 50%;
  border: 1px solid #000;

  &-rega {
    background-color: $color-presets-rega;
  }
  &-military {
    background-color: $color-presets-military;
  }
  &-heliport {
    background-color: $color-presets-heliport;
  }
  &-hospital {
    background-color: $color-presets-hospital;
  }
  &-route {
    background-color: $color-presets-route;
  }
}
