.form-item {
  padding-bottom: 1.5em;
}

.form-item-wrapper {
  position: relative;
  padding-bottom: 1.2em;
}

.form-label {
  position: absolute;
  left: 0;
  bottom: 0;

  font-size: 1em;
}

.form-item-error {
  margin: 0;
  padding: 0.2em 0.5em;

  background-color: red;
  border-radius: 3px;
  color: white;
}
