.form-select {
  margin: 0;
  padding: 0 14px;

  width: 100%;
  height: 38px;
  line-height: 38px;

  border: 1px solid #0f0;
  border-radius: 4px;
  background-color: #1c251c;
  color: #0f0;

  // https://stackoverflow.com/a/38764639
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('./../../img/select-arrow.png');
  background-repeat: no-repeat;
  background-position: 98% center;

  font-size: 1.5em;

  outline: 0;
}
