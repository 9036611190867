.widget .leaflet-control-fullscreen {
  margin: 0 !important;

  a {
    @extend .button;

    &,
    &:hover,
    &:focus {
      border-top: 0;
      border-left: 0;
      background-color: #1c251c;
    }

    &:before {
      @extend %sprite;

      position: absolute;
      top: 50%;
      left: 50%;

      width: sprite-width('fullscreen-enable');
      height: sprite-height('fullscreen-enable');
      margin-left: sprite-width('fullscreen-enable') / 2 * -1;
      margin-top: sprite-height('fullscreen-enable') / 2 * -1;
      background-position: sprite-position('fullscreen-enable');

      content: '';
    }
  }
}

.widget .leaflet-fullscreen-on .leaflet-control-fullscreen a:before {
  background-position: sprite-position('fullscreen-disable');
}
