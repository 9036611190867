/*  ____        _   _
// | __ ) _   _| |_| |_ ___  _ __
// |  _ \| | | | __| __/ _ \| '_ \
// | |_) | |_| | |_| || (_) | | | |
// |____/ \__,_|\__|\__\___/|_| |_|
*/

.button {
  &,
  &:visited {
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    padding: 0;

    font: inherit;
    border-radius: 0;
    border: 1px solid #0f0;
    background: none;
    color: #0f0;

    cursor: pointer;
  }

  &:focus,
  &:hover,
  &:active {
    outline: 0;
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-tap-highlight-color
    -webkit-tap-highlight-color: transparent;
  }
}
