.form-textinput {
  @extend %font-weight-bold;

  width: 100%;
  height: 34px;
  line-height: 34px;

  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #0d6d0d;
  background-color: transparent;
  color: #0f0;

  font-size: 1.5em;

  outline: 0;
}
