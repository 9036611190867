.layer-gridapi-error {
  @extend %font-weight-bold;
  padding: 0 0.5em;

  height: 2em;
  line-height: 2em;

  font-size: 1.2em;

  background: crimson;
  color: white;

  & + & {
    display: none;
  }
}
