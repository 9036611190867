.widget-control-icinglegend {
  margin: 0 !important;
  padding: 0.2em 0.5em;
  width: 12em;

  background-color: #1c251c;
  color: #0f0;

  border: 1px solid #0f0;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;

  .icinglegend-levels {
    @extend %list-style-none;
  }

  .icinglegend-level {
    position: relative;
    padding-left: 20px;
  }

  .icinglegend-color {
    position: absolute;
    top: 0.3em;
    left: 0;

    display: block;
    width: 10px;
    height: 10px;
  }
}
