.crosssection-intro {
  position: relative;
  padding: 2em 1em 0;
  height: 100%;
  line-height: 1.6em;
  font-size: 1.8em;
  text-align: center;

  p {
    margin: 0;
  }
}

.crosssection-intro-links {
  @extend %clearfix;
  @extend %list-style-none;

  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 1em;
  left: 0;

  li {
    float: left;
    width: 33.333%;
  }

  a {
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  &-admin a {
    width: 137px;
    height: 35px;
    background-image: url('./../../img/logos/logo-admin.svg');
  }

  &-meteotest a {
    width: 44px;
    height: 41px;
    background-image: url('./../../img/logos/logo-meteotest.svg');
  }

  &-rega a {
    width: 158px;
    height: 70px;
    background-image: url('./../../img/logos/logo-rega.svg');
  }

  &-airforce a {
    width: 50px;
    height: 50px;
    background-image: url('./../../img/logos/logo-airforce.svg');
  }
}

.crosssection-image {
  position: relative;

  height: 100%;

  &-altitude {
    @extend %font-weight-normal;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 0 5px;

    color: #ff3131;
    font-size: 15px;
    line-height: 15px;

    border-bottom: 2px solid #ff3131;
  }
}

.crosssection-loading {
  height: 100%;
  padding-top: 2.5em;
  text-align: center;
  font-size: 3em;

  p {
    margin: 0;
  }
}

@media (max-width: 900px) {
  .crosssection-intro-links {
    &-admin a {
      width: 137px * 0.7;
      height: 35px * 0.7;
    }

    &-meteotest a {
      width: 44px * 0.7;
      height: 41px * 0.7;
    }

    &-rega a {
      width: 198px * 0.7;
      height: 40px * 0.7;
    }
  }
}
